import { createStore, createHook } from 'react-sweet-state';

type InitialState = {
	firstRedactionIdRendered: string | null;
	titleRedactionRendered: boolean;
	activeSpotlightIndex: number | null;
	lastActiveSpotlightIndex: number | null;
};

const initialState: InitialState = {
	firstRedactionIdRendered: null,
	titleRedactionRendered: false,
	activeSpotlightIndex: null,
	lastActiveSpotlightIndex: null,
};

const Store = createStore({
	initialState,
	actions: {
		setFirstRedactionIdRendered:
			(redactionId: string) =>
			({ getState, setState }) => {
				const { firstRedactionIdRendered } = getState();
				if (firstRedactionIdRendered) return;

				setState({
					firstRedactionIdRendered: redactionId,
				});
			},
		setTitleRedactionRendered:
			() =>
			({ setState }) => {
				setState({
					titleRedactionRendered: true,
				});
			},
		resetTour:
			() =>
			({ setState }) => {
				setState({
					activeSpotlightIndex: initialState.activeSpotlightIndex,
					lastActiveSpotlightIndex: initialState.lastActiveSpotlightIndex,
				});
			},
		initiateTour:
			() =>
			({ setState }) => {
				setState({
					activeSpotlightIndex: 0,
				});
			},
		proceedToNext:
			() =>
			({ setState, getState }) => {
				const { activeSpotlightIndex } = getState();
				if (activeSpotlightIndex === null) return;

				setState({
					activeSpotlightIndex: activeSpotlightIndex + 1,
					lastActiveSpotlightIndex: activeSpotlightIndex,
				});
			},
		concludeTour:
			() =>
			({ setState, getState }) => {
				const { activeSpotlightIndex } = getState();

				setState({
					activeSpotlightIndex: null,
					lastActiveSpotlightIndex: activeSpotlightIndex,
				});
			},
	},
});

export const useRedactionsTourState = createHook(Store);
